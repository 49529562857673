.qty-btn {
    border: 1px solid #ccc;
}


 span.pricing-sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .ec-mobile-menu {
    display: none;
}
.ec-mobile-menu.open {
    display: block;
}
.qty-plus-minus {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 40px;
    overflow: hidden;
    width: fit-content;

}

.qty-btn {
    color: #0f0e0e;
    border: 1px solid #ccc;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    width: 40px;
    outline: none;
    transition: all 0.3s ease;
}
#input-qty-btn{
    height: 15px;
}

.qty-input {
    width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    padding: 5px;
    outline: none;
    margin: 0;
    border: none;
    transition: border-color 0.3s ease;
}





  /* Slider item fade-in and slide-up animation */
@keyframes fadeInSlideUp {
  0% {
      opacity: 0;
      transform: translateY(100%);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Slider item fade-out and slide-down animation */
@keyframes fadeOutSlideDown {
  0% {
      opacity: 1;
      transform: translateX(0);
  }
  100% {
      opacity: 0;
      transform: translateX(-110%);
  }
}

/* Apply animation to the active slide item */
.ec-slide-item.active {
  animation: fadeInSlideUp 1s ease-in-out;
}

/* Apply animation to the inactive slide item (exit animation) */
.ec-slide-item:not(.active) {
  animation: fadeOutSlideDown 1s ease-in-out;
}

/* Optional: smooth transition for the background change */
.ec-slide-item {
  transition: opacity 1s ease-in-out;
}






/* Custom Paginator Styles */
.custom-paginator {
  background-color: #f8f9fa; /* Light background */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding around the paginator */
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  /* gap: 10px; */
}

.custom-paginator .p-paginator-page {
  border: 1px solid #007bff; /* Border for page buttons */
  border-radius: 5px; /* Rounded corners for buttons */
  padding: 8px 12px; /* Padding inside buttons */
  color: #007bff; /* Text color */
  margin: 0 5px; /* Space between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.custom-paginator .p-paginator-page:hover {
  background-color: #00a9bf; /* Background on hover */
  color: white; /* Text color on hover */
  border: 1px solid #00a9bf; /* Border for page buttons */
  
}

/* Active page styling with aria-current="true" */
.custom-paginator .p-paginator-page[aria-current="true"] {
  background-color: #007bff; /* Active page background */
  color: white; /* Active page text color */
  font-weight: bold; /* Bold text for active page */
}

.custom-paginator .p-paginator-prev,
.custom-paginator .p-paginator-next {
  background-color: #00a9bf; /* Background color for prev/next buttons */
  color: white; /* Text color for prev/next buttons */
  border: none; /* Remove border */
  padding: 8px 12px; /* Padding inside buttons */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth transition */
}

.custom-paginator .p-paginator-prev:hover,
.custom-paginator .p-paginator-next:hover {
  background-color: #07c4e1; /* Darker blue on hover */
  margin-bottom: 7px;
}





/* Styling for both the "First" and "Last" buttons */
.custom-paginator .p-paginator-first,
.custom-paginator .p-paginator-last {
  background-color: #00a9bf; /* Set background color for the buttons */
  border: none; /* Remove any border */
  color: white; /* Set text/icon color */
  padding: 8px 12px; /* Padding inside the buttons */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and color */
}

.custom-paginator .p-paginator-first:hover,
.custom-paginator .p-paginator-last:hover {
  background-color: #07c4e1; /* Darker background on hover */
  color: white; /* Ensure icon color is still white on hover */
  margin-bottom: 7px;
}

/* Targeting the SVG icon inside the "First" and "Last" buttons */
.custom-paginator .p-paginator-first .p-icon,
.custom-paginator .p-paginator-last .p-icon {
  fill: currentColor; /* Ensure SVG icon inherits the color of the button */
  transition: fill 0.3s; /* Smooth transition for fill color */
}

/* Optional: Customize hover effect for the SVG icon */
.custom-paginator .p-paginator-first:hover .p-icon,
.custom-paginator .p-paginator-last:hover .p-icon {
  fill: white; /* Change icon color to white on hover */
}
