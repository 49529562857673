
.payment {
    font-family: Arial, sans-serif;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 20px;
    padding: 20px;
}

.payment-container {
    background-color: #f1f1f1;
    border-radius: 20px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 600px;
    padding: 30px 40px;
}
h2 {
    text-align: center;
    color: #0070ba;
}
.form-group {
    margin-bottom: 15px;
    width: 100%;
}
.form-group label {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom: 5px;
}
.form-group input {
    width: 100%;
    padding: 30px 20px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: #fff;
}

/* Expiry Date & CVV on the same row for large screens */
.form-group-2 {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.form-group-2 .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.pay-button {
    background-color: #0070ba;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
}
.pay-button:hover {
    background-color: #005fa3;
}
.card-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-icons img {
    width: 60px;
    height: auto;
}
.payment-note {
    text-align: center;
    font-size: 13px;
    margin-top: 20px;
    color: #777;
}

/* Responsive Design */
@media (max-width: 480px) {
    .form-group-2 {
        flex-direction: column;
    }
    .card-icons img {
        width: 40px;
    }
    .payment {
        padding: 10px;
    }
    .payment-container {
        padding: 30px 20px;
    }
}

