
  
  .content {
    padding: 15px;
    overflow: hidden;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  
  .clear {
    clear: both;
  }
  

  .load-wrapp {
    float: left;
    width: 100px;
    height: 100px;
    margin: 0px 10px 10px 0px;
    padding: 20px 20px 20px;
    border-radius: 5px;
    text-align: center;
    background-color: transparent;
  }
  

  .load-wrapp:last-child {
    margin-right: 0;
  }

  .load-wrapp p {
    padding: 0 0 20px;
  }
  
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right:5px;
    background-color: #4b9cdb;
  }
  
  
  .load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
  }
  .load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
  }
  .load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
  }
  
  
  @keyframes loadingC {
    0 {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
 