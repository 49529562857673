.ec-mobile-menu {
    display: none;
}
.ec-mobile-menu.open {
    display: block;
}
.qty-plus-minus {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 40px;
    overflow: hidden;
    width: fit-content;

}


.qty-btn {
    color: #0f0e0e;
    border: 1px solid #ccc;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    width: 40px;
    outline: none;
    transition: all 0.3s ease;
}
#input-qty-btn{
    height: 15px;
}

.qty-input {
    width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    padding: 5px;
    outline: none;
    margin: 0;
    border: none;
    transition: border-color 0.3s ease;
}


.search-suggestions {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    z-index: 100;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.search-suggestions li {
    padding: 10px 12px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease-in-out;
}

.search-suggestions li:hover {
    background-color: #f9f9f9;
}


.search-box {
    position: absolute; 
    float: left;
    max-height: 500px;
    overflow: scroll;
    margin: 0px 20px;
    z-Index: 9999;
    background-color: #fff;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 10px 10px;
    animation: moveTopToBottom 0.3s ease-out forwards;
    
  }
  .search-box ul {
    list-style: none;
  }
  
  
  
  .search-box li {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #f0f0f0;
    margin: 5px 10px;
  }
  
  .search-box li:hover {
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
    
    background-color: #38a1e7;
    color: #fff;
    font-weight: 500;
  }

/* For screens with a width of 580px or more */
@media (min-width: 580px) {
    .search-box {
      margin: 0px 20px;
      padding: 30px;
      width: calc(100% - 40px);
    }
  }
  
  /* For screens with a width of 579px or less */
  @media (max-width: 579px) {
    .search-box {
      margin: 0px 5px;
      padding: 5px;
      width: calc(100% - 10px);
    }
  }
  


  @keyframes moveTopToBottom {
    0% {
      height: 0; /* Start from 0 height */
    }
    100% {
      height: auto; /* Expand to the content height */
    }
  }